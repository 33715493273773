@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  font-size: 16px;
}

body {}

.modal-overlay,
.ReactModal__Overlay {
  z-index: 20;
}

/* body {
  background-image: url(./assets/images/footer-bg.jpg);
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: contain;
}

@media (min-width: 760px) {
  body {
    background-size: 1500px auto;
  }
} */
.woman-bg {
  background-image: url(./assets/images/mywic-woman-bg.jpg);
  background-position: right center;
  background-repeat: no-repeat;
  background-size: cover;
  /* background-attachment: fixed; */
}

@media (max-width: 768px) {
  .woman-bg {
    background-image: none;
    /* background-position-x: center;
    background-position-y: 50px; */
  }
}

.text-gradient {
  background: linear-gradient(150deg, #DF8564 23.66%, #B94787 91.92%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.bg-gradient {
  background: linear-gradient(122.18deg, #DF8564 23.66%, #B94787 91.92%);
}